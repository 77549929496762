import {useState} from 'react';
import {Outlet} from 'react-router-dom';
// @mui
import {styled} from '@mui/material/styles';
import {Box} from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import {HEADER, NAVBAR} from '../../config';
//
import DashboardHeader from '../dashboard/header';
import Div100vh from "react-div-100vh";

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
    shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({collapseClick, theme}) => ({
    flexGrow: 1,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    paddingBottom: 0,
    width: '100%',
    [theme.breakpoints.up('lg')]: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
        paddingBottom: 0,
        width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
        transition: theme.transitions.create('margin-left', {
            duration: theme.transitions.duration.shorter,
        }),
        ...(collapseClick && {
            marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
        }),
    },
}));

// ----------------------------------------------------------------------

export default function ModerationLayout() {
    const {collapseClick, isCollapse} = useCollapseDrawer();

    const {themeLayout} = useSettings();

    const isDesktop = useResponsive('up', 'lg');

    const [open, setOpen] = useState(false);

    const verticalLayout = themeLayout === 'vertical';

    return (
        <Div100vh className="bg-white">
            <Box
                sx={{
                    display: 'flex',
                    minHeight: 1,
                }}
            >
                <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)}/>

                {/*<NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />*/}

                <MainStyle collapseClick={collapseClick}>
                    <Outlet/>
                </MainStyle>
            </Box>
        </Div100vh>
    );
}
