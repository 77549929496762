import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import DashboardLayout from "./layouts/dashboard";
import {BrowserRouter} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

// import { Provider as ReduxProvider } from 'react-redux';
// import { PersistGate } from 'redux-persist/lib/integration/react';
// @mui
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// redux
// import { store, persistor } from './redux/store';
// contexts
import {SettingsProvider} from './contexts/SettingsContext';
import {CollapseDrawerProvider} from './contexts/CollapseDrawerContext';
import {AuthProvider} from "./contexts/FirebaseContext";
import {QueryClient, QueryClientProvider} from "react-query";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(

        <AuthProvider>

            <HelmetProvider>
                {/*<ReduxProvider store={store}>*/}
                {/*    <PersistGate loading={null} persistor={persistor}>*/}
                {/*        <LocalizationProvider dateAdapter={AdapterDateFns}>*/}
                <QueryClientProvider client={queryClient}>
                    <SettingsProvider>
                        <CollapseDrawerProvider>
                                <BrowserRouter>
                                    <App/>
                                </BrowserRouter>
                        </CollapseDrawerProvider>
                    </SettingsProvider>
                </QueryClientProvider>
                {/*</LocalizationProvider>*/}
                {/*</PersistGate>*/}
                {/*</ReduxProvider>*/}
            </HelmetProvider>

        </AuthProvider>
    ,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
