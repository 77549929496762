
import {AxiosResponse} from "axios";
import {UserSummary} from "../models/UserSummary";
import api from "./index";

class UserService {

    getUserSummary = (userId: string): Promise<UserSummary> => {
        return api
            .get(`auth/users/summary/${userId}`)
            .then((response: AxiosResponse) => response.data);
    };
}

export const userService = new UserService();
